import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Service from '../components/services/Service';

import Screw from '../svg/screw.svg';

const Consommables = props => {
  const title = 'Consommables';
  const services = ['Entretoises', 'Adhésifs', 'Produits de fixation', 'Porte plaque', 'Chevalets', 'Chainettes', 'Clips', 'Chevalets'];
  return (
    <Layout>
      <SEO title={title} description="Marougrav fournit aussi tout le matériel nécessaire pour installer vos gravures. Comme par exemple des adhésifs, chevalets, supports ou fixations." keywords={['gravure']} />
      <Service title={title} services={services} images={props.data.allFile.edges} color="#007A9B">
        <Screw />
      </Service>
    </Layout>
  );
};

export const query = graphql`
  {
    allFile(filter: {extension: {eq: "jpg"}, relativeDirectory: {eq: "conso"}}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default Consommables;


